<script>
import sanitizer from '@/util/htmlSanitizer'

export default {
  props: ['columns', 'datas', 'baseUrl'],
  data() {
    return {}
  },
  mounted() {},

  methods: {
    sanitizer,
    RouteToDetail(id) {
      this.$router.push(`${this.baseUrl}/${id}`)
    }
  }
}
</script>

<template>
  <div class="wf__table" ref="table">
    <div class="wf__tr">
      <div class="wf__th" v-for="(column, i) in columns" :key="i">
        <span v-html="`${sanitizer(column)}`"></span>
      </div>
    </div>

    <div class="wf__tr cursor-pointer" v-for="(data, index) in datas" :key="index" @click="RouteToDetail(data.id)">
      <div class="wf__td" :class="{ 'text-center': j === 0 }" v-for="(item, j) in data.data" :key="j">
        <span v-if="typeof item !== 'object'" v-html="`${sanitizer(item)}`"></span>
        <div v-else class="wf__board-list-item">
          <span>
            {{ item.textContents.title }}
            <div class="wf__board-list-subtitle-item">
              <span v-for="(sub, si) in item.textContents.subtitle" v-show="sub" :key="si">{{ sub }}</span>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__table {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;

  border: 1px solid var(--wf-secondary-color);
  border-left: none;
  border-right: none;
  margin: 10px 0;

  overflow-x: scroll;

  font-size: var(--wf-text-16);
}
.wf__table::-webkit-scrollbar {
  display: none;
}
.wf__tr {
  display: flex;
  overflow: visible;
  padding: 1em 0;
  border-bottom: 1px solid var(--wf-gray-400);
}
.wf__tr:last-child {
  border-bottom: none;
}
.wf__th {
  text-align: center;
  font-weight: 600;
}
.wf__tr > .wf__td,
.wf__tr > .wf__th {
  flex: 1;
  padding: 0 1em;
}
.wf__tr > .wf__th:not(:last-child) span,
.wf__tr > .wf__td:not(:last-child) span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wf__tr > .wf__th {
  border-right: 1px solid var(--wf-secondary-color);
}

.wf__tr > .wf__td:first-child,
.wf__tr > .wf__th:first-child {
  max-width: 70px;
}

.wf__tr > .wf__td:nth-child(2),
.wf__tr > .wf__th:nth-child(2) {
  max-width: calc(100% - 280px);
}

.wf__tr > .wf__td:last-child,
.wf__tr > .wf__th:last-child {
  min-width: 13em;
  max-width: 13em;
}

.wf__board-list-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.wf__board-list-subtitle-item {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--wf-text-14);
  color: var(--wf-gray-color);
}

.wf__board-list-subtitle-item > span:not(:last-child)::after {
  content: '/';
  padding: 0.5em;
}
.wf__board-list-subtitle-item > span:last-child {
  padding-right: 1px;
}

@media screen and (max-width: 576px) {
  .wf__board-list-subtitle-item > span:last-child {
    width: 100%;
    white-space: pre-wrap;
  }
}
@media screen and (max-width: 992px) {
  .wf__tr > .wf__td:last-child,
  .wf__tr > .wf__th:last-child {
    display: none;
  }

  .wf__tr > .wf__td:nth-child(2),
  .wf__tr > .wf__th:nth-child(2) {
    max-width: calc(100% - 70px);
  }
}
</style>
