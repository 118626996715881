<script setup>
import DynamicCommunityListTableVue from '@/components/Community/DynamicCommunityListTable.vue'
import PaginationBarVue from '../../../components/ui/PaginationBar.vue'
import SearchBarVue from '../../../components/ui/SearchBar.vue'
import LoadingVue from '@/components/ui/Loading.vue'

import { searchPatent } from '@/api/platform/search.js'

import { ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import LanguageConverter from '@/util/LanguageConverter'

const store = useStore()
const route = useRoute()
const router = useRouter()
const isLoading = ref(false)
const conditions = store.state.searchStore.conditions

const columns = [
  LanguageConverter({ en: 'Num', kr: '번호' }),
  LanguageConverter({ en: 'Title', kr: '출원등록명' }),
  LanguageConverter({ en: 'Lab', kr: '연구실' })
]
const datas = ref([])
const dataCount = ref(0)

const paginationClickHandler = async (e) => {
  datas.value = await LoadData(e.itemIdx)
}

const searchAction = async (keyword, option) => {
  router.push(`/community/patent?q=${keyword}&opt=${option}`)
}

const executeSearchAction = async (searchOption) => {
  if (!searchOption.all) {
    return await searchPatent(searchOption)
  }

  const opt = { type: 'OR', ...searchOption }
  for (const condition of conditions) {
    opt[condition.value] = route.query.q
  }
  delete opt.all

  return await searchPatent(opt)
}

const LoadData = async (pageIdx = 1) => {
  isLoading.value = true
  const paginationOption = { limit: 10, offset: (pageIdx - 1) * 10 }
  const searchOption = { ...paginationOption }
  if (route.query.q) {
    searchOption[route.query.opt] = route.query.q
  }

  const data = await executeSearchAction(searchOption)

  if (data.code === 'DB_DATA_NOT_FOUND') {
    dataCount.value = 0
    isLoading.value = false
    return []
  }

  if (dataCount.value !== data.count) dataCount.value = data.count

  const result = data.items.map((item, idx) => {
    const no = paginationOption.offset + idx + 1
    return {
      id: item.patentId,
      data: [
        no,
        {
          textContents: {
            title: item.patentResultTitle,
            subtitle: [
              `(${item.patentResultID})`, //
              `${item.patentYear ? new Date(item.patentYear).getFullYear() : ''}`,
              item.patentRegistType,
              item.patentIprType,
              item.patentRegistrant
            ]
          }
        },
        item.labName
      ]
    }
  })

  isLoading.value = false
  return result
}

onMounted(async () => {
  datas.value = await LoadData()
})

watch(
  () => route.query.q,
  async () => {
    datas.value = await LoadData()
  }
)
watch(
  () => route.query.opt,
  async () => {
    datas.value = await LoadData()
  }
)
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <section class="body-section">
    <h2 class="wf__community-default-title">{{ LanguageConverter({ en: 'Patents', kr: '지재권 게시판' }) }}</h2>

    <DynamicCommunityListTableVue v-if="datas.length !== 0" class="mb-5" :columns="columns" :datas="datas" :baseUrl="route.path" />

    <div v-if="datas.length === 0">결과가 없습니다.</div>
    <PaginationBarVue v-if="dataCount !== 0" :totalItems="dataCount" :itemsPerPage="10" @onItemClick="paginationClickHandler" />
  </section>

  <section class="search-section">
    <SearchBarVue @search="searchAction" :searchConditions="conditions" />
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.head-section {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}
.wf__subtitle {
  font-size: 1.25rem;
}
.body-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 5em;
  margin-bottom: 3em;
}
.body-section h2 {
  font-weight: bold;
  margin-bottom: 1em;
}
.search-section {
  margin-bottom: 5em;
}
</style>
